const MainPage = () => {
  let imgSource = "images/homepage.jpeg";
  const { innerWidth: width, innerHeight: height } = window;
  if (width < height) {
    imgSource = "images/homepage_v.jpeg";
  }
  return (
    <div className="homepage-img">
      <img src={imgSource} alt="consulting-trio-tim" />
    </div>
  );
};

export default MainPage;
