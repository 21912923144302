import { createBrowserRouter } from "react-router-dom";
import MainPage from "../pages/MainPage";

export const ERoutes = {
  MainPage: "/",
  Anything: "*",
};

export const router = createBrowserRouter([
  { path: ERoutes.MainPage, element: <MainPage /> },
  { path: ERoutes.Anything, element: <MainPage /> },
]);
